:root, body {
	overscroll-behavior: none;
}

html {
	box-sizing: border-box;
	font-size: 18px;
	width: 100%;
	height: 100%;
}

body {
	width: 100%;
	height: 100%;

	background:  radial-gradient(circle, rgba(24, 58, 85, 1) 1%, rgba(0, 18, 37, 1) 100%);
	background-size: 300% 150%;
	animation: gradient 7s ease infinite;

	color: $white-color;
	font-family: $primary-font;
	font-size: $fw-size;
	font-weight: $fw-light;
	line-height: $line-height;
  
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;

	margin: 0;
	overflow-x: hidden;


	&::-webkit-scrollbar {
		width: 5px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px 20px rgba(255, 255, 255);
	}

	&::-webkit-scrollbar-thumb {
		background-color: $secondary-color;
		outline: 2px solid $secondary-color;
	}
}

html, body {
	touch-action: pan-y; /* Permite pan y swipe, pero no pinch-zoom */
}


.site-content {
	height: 100%;
    position: relative;
    z-index: 10;
	padding: 50px 0;

	.color_green {
		color: $primary-color;
	}

	.label-flotante {
		position: relative;
		margin-bottom: 18px;

		.form-label {
			position: absolute;
			font-weight: $fw-medium;
			top: 12px;
			z-index: 2;
			left: 12px;
			pointer-events: none;
			
			transition: transform .2s ease-out, color .2s ease-out;
			transform-origin: 0% 100%;
			text-align: initial;
			transform: translateY(10px);
			background-color: transparent;
			display: block;
			padding: 0 3px;
			cursor: text;
		}
		&.not-valid {
			.form-label { color: #E85D64; }
		}
		&.activo {
			.form-label {
				transform-origin: 0 0;
				font-weight: $fw-light;
				transform: translateY(-6px) scale(0.8);
			}	
		}


	}	

	.form-control {
		border: 2px solid #FFFFFF;
		color: #FFFFFF;
		font-weight: $fw-medium;
		border-radius: 15px;
		background-color: transparent;
		padding: 24px 12px 15px 12px;

		&:focus {
			box-shadow: unset;
		}
	}




	.form-check {
		
		position: relative;

		.form-check-label {
			font-size: 11px;
			a {
				color: #FFFFFF;
				font-weight: $fw-medium;
				text-decoration: none;
			}
		}	

		input[type=checkbox] {
			display: none;
		}
		label:before {
			content: '';
			width: 15px;
			height: 16px;
			border: 2px solid #FFFFFF;
			border-radius: 3px;
			position: absolute;
			left: 0;
			top: 4px;
			background-color: transparent;
			cursor: pointer;

		}	
		input[type=checkbox]:checked + :before {
			content: '';
			background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");;
		}
	}

	.btn {
		width: fit-content;
		height: max-content;
		max-height: 42.39px;
		border: none;
		padding: 10px 20px;
		border-radius: 50px;
		text-decoration: none;
		cursor: pointer;	
	  
		font-size: $fw-size;
		transition: all 0.35s ease;
		position: relative;
	  
		overflow: hidden;
	  
		// btn-primary
		&-primary {
			font-weight: $fw-regular;
		 	color: $secondary-color !important;
		  	background-color: $primary-color !important;
		}
		&-seccondary {
			color: $secondary-color !important;
			background-color: #FFFFFF;
			img {
				position: relative;
				margin-top: -5px;
				margin-right: 5px;
			}

			&:active {
				background-color: #FFFFFF !important;
			}
		}
	  
		&:disabled {
		  background-color: $disabled-color;
		}
	    
	}

	.link {
		text-decoration: none;
		color: $white-color !important;
	}	
	  
}


.head-bar {
	display: none;
	width: 100%;
	position: fixed;
	z-index: 999;
	top: 0;
	background-color: rgba(0, 18, 37, 0.8);
	backdrop-filter: blur(10px);	
	&.active {
		display: block;
	}

	h2 {
		font-family: $secondary-font;
		font-weight: $fw-bold;
		font-size: 16px;
		line-height: 22.4px;
		letter-spacing: -0.16px;
		margin:0;
		padding: 16px 0;
	}

	
	.back { 
		position: absolute;
		left: 12px;
		top: 14px;
	}
}

.tab-bar {
	width: 100%;

	position: fixed;
	z-index: 999;
	bottom: 0;
	
	background-color: rgba(0, 18, 37, 0.8);
	backdrop-filter: blur(10px);

	ul {
		list-style: none;
		margin: 0 0 10px 0;
		padding: 0;
		li {
			display:inline-block;
			margin: 0 5px;
			max-width: 95px;
			width: calc(25% - 10px);		
			&:first-child {
				margin-left: 0;
			}	
			 &:last-child {
				margin-right: 0;
			}	
			a {
				display: block;
				padding: 19px 0px;
				&.active {					
					border-top: 2px solid $primary-color;
					.st1 {
						fill: $primary-color;
					}					
				}

				svg {
					.st1 {
						fill: #FFFFFF;
					}					
					width: 21px;
				}
			}
		}
	}
}

.home {
	.video-container {
		position: fixed;
		min-height: 100vh;
		width: 100vw;

		&:after {
			content:"";
			position: absolute;
			width: 100%; 
			height: 100%;
			background-color:rgba(0,0,0,0.4);
			z-index: 100;
		}
		.video {
			position: fixed;
			min-height: 100vh;
			width: 100vw;
			object-fit: cover;
			top: 0;
			left: 0;
			z-index: 1;
		}
	}

	.site-branding_logo {
		margin: 0 auto;
		//max-width: 50%;
	}

	h1 {
		font-family: $secondary-font;
		font-weight: $fw-black;
		font-size: 49px;
		line-height: 50px;
		letter-spacing: -1.96px;

		text-transform: uppercase;

		margin: 40px 0;
	}

	.welcome { 
		margin-bottom: 40px;
	}
}

.login {
	.site-branding_logo {
		margin: 0 auto;
		//max-width: 30%;
	}
	.texto {
		margin-top: 38px;
		margin-bottom: 28px;
	}

	.btn-primary {
		margin: 18px 0;
	}
	.row-bot {
		--bs-gutter-x: 0.75rem;
		margin-bottom: 30px;
	}
}

.content {
	height: 100%;
    position: relative;	
	z-index: 9;
}

.div-info {

	.site-branding_logo {
		margin: 0 auto;
		//max-width: 30%;
	}	
	.h1 {
		margin-top: 25px;
		margin-bottom: 35px;

		font-family: $secondary-font;
		font-size: 25px;
		font-weight: $fw-medium;
		line-height: 27px;
		letter-spacing: -0.75px;

		p {
			margin: 0 0 10px 0;
		}
	}
	
}

.cat {
	display: inline-block;
	font-size: 11px;
	line-height: 14px;
	text-transform: uppercase;
	background-color: $primary-color;
	color: $secondary-color;
	border-radius: 50px;
	padding: 1px 11px 2px 11px;;

	margin-bottom: 18px;
}
.cards {
	margin-bottom: 100px;
	.box {
		width: 100%;
		background-color: rgba(255, 255, 255, 0.1);
		border-radius: 15px;
		padding: 16px 16px 16px 16px;
		margin-bottom: 16px;
		position: relative;

		a { 
			text-decoration: none; 
			display: block;
			position: relative;
			z-index: 99;
		}

		.icon {
			position: absolute;
			width: 21px;
			height: 21px;
		}

		&.date {
			&::before {
				content: '';
				position: absolute;
				width: 21px;
				height: 21px;
				background: url('../images/ico_clock.svg') no-repeat top left;
			}
		}

		&.location {
			&::before {
				content: '';
				position: absolute;
				width: 21px;
				height: 21px;
				background: url('../images/ico_location.svg') no-repeat top left;
			}
		}

		&.medical {
			&::before {
				content: '';
				position: absolute;
				width: 21px;
				height: 21px;
				background: url('../images/ico_medical.svg') no-repeat top left;
			}
		}	

		&.money {
			&::before {
				content: '';
				position: absolute;
				width: 21px;
				height: 21px;
				background: url('../images/ico_money.svg') no-repeat top left;
			}
		}	
		
		&.link {
			&::after {
				content: '';
				position: absolute;
				width: 15px;
				height: 14px;
				top: 20px;
				right: 16px;
				opacity: 0.3;
				background: url('../images/ico_next.svg') no-repeat top left;
			}			
		}	
		

		.tit {
			padding-top: 31px;
			margin-bottom: 7px;
			color: $primary-color;
		}
		.text {
			color: $white-color;
		}

		.accordion-box {
			display: block;
			&::after {
				content: '';
				position: absolute;
				width: 15px;
				height: 14px;
				top: 7px;
				right: 0;
				background: url('../images/ico_down.svg') no-repeat top left;
				transform: rotate(180deg);
				
			}	
			&:not(.show) {
				&::after {
					background: url('../images/ico_down.svg') no-repeat top left;	
					transform: rotate(0deg);
				}
			}

		}			

		.box-item {
			margin-top: 10px;
			display: none;
		}
	}
}
.div-music {
	.cards {
		margin-top: 30px;	
		.room {	
			margin-bottom: 30px;	
			.item {
				width: 100%;
				background-color: rgba(255, 255, 255, 0.1);
				padding: 16px 16px 16px 16px;
				margin-bottom: 1px;
				position: relative;

				&:first-child {
					border-top-left-radius: 15px;
					border-top-right-radius: 15px;
				}
				&:last-child {
					border-bottom-left-radius: 15px;
					border-bottom-right-radius: 15px;
				}

				display: flex;
				.img { 					
					width: 36px;
					margin-right: 16px;
					img {
						border-radius: 10px;
						width: 100%;
					}
				}
				.hora {
					font-size: 11px;
					line-height: 1.1;
				}
				.artista {
					font-family: $secondary-font;
					font-weight: $fw-bold;
					font-size: 16px;

				}
			}
		}
	}
}

.div-user {
	ul {
		margin: 20px 0 0 0;
		padding: 0;
		list-style-type: none;

		a {
			position: relative;
			padding: 10px 0px;
			display: block;
			color: #FFFFFF;
			text-decoration: none;

			&::after {
				content: '';
				position: absolute;
				width: 15px;
				height: 14px;
				top: 17px;
				right: 0px;				
				background: url('../images/ico_next.svg') no-repeat top left;				
			}			

			&.active {
				color: $primary-color;
				&::after {	
					background: url('../images/ico_next_active.svg') no-repeat top left;				
				}				
			}
		}
	}
}

.pinch-zoom-container {
	position: fixed;
	height: 100vh;
	width: 100vw;
	overflow: hidden;

	display: flex;
	align-items: center;
	//justify-content: center;

	#pinch-zoom-image-id {
		//touch-action: auto;
		//transform: scale(0.6);
		width: 100%;
		@include media-breakpoint-up(md) {
			margin: 0 auto;
			max-width: 770px;
		}	

	}
}

.modal {
	--bs-modal-margin: 63px;
	--bs-modal-width: 250px;
	.modal-content {
		color: $secondary-color;
		border-radius: 15px;
		
		a {
			text-decoration: none;
			color: $secondary-color;
			&.red {
				color: #FF0000;
			}
		}
		
		.modal-body {
			padding: 20px 18px;
			text-align: center;
			font-size: 13px;
			line-height: 16px;
			.tit {
				font-weight: $fw-medium;
				font-size: 15px;
				line-height: 20px;
				margin-bottom: 12px;
			}
		}
	
		.modal-footer {
			padding: 5px 16px;
			justify-content: center;
		}
	
	}	
}


@keyframes gradient {
	0% {
	  background-position: 0% 0%;
	}
  
	50% {
	  background-position: 100% 0%;
	}
  
	100% {
	  background-position: 0% 0%;
	}
}
